import axios from 'axios'

const API_ENDPOINT = process.env["REACT_APP_ATPL_BE_API_URL"]

// const CancelToken = axios.CancelToken;
// export const CancelTokenSource = CancelToken.source();

export const axiosCore = axios

const instance = axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true,
  // cancelToken: CancelTokenSource.token
})

instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error && error?.response?.status === 401) {
      document.cookie = 'ACCESS_GRANTED=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      if (window.location.pathname !== '/login') {
        window.location.href = '/login'
      }
    }
    return Promise.reject(error)
  }
)


export default instance
