import React, { ReactNode, useEffect, useState } from 'react'

import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'

import { tokenExpired } from './helpers/cookies'
import routes from './routes'
import axios from "./helpers/axios";

interface route {
  path?: string
  element: ReactNode
  protect?: boolean
  index?: boolean
  nestedPaths?: route[]
}

const App: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(!tokenExpired())

  useEffect(() => {
    setLoggedIn(!tokenExpired())
  }, [])

  const renderElement = ({ protect, element }: route) => {
    return protect && !loggedIn ? <Navigate to="/login" replace={true} /> : element
  }

  const renderRoutes = (route: route, index: number | string) => {
    const { nestedPaths, ...restProps } = route
    const path = restProps.path
    if (nestedPaths) {
      // @ts-ignore
      return <Route key={`${index}-${path}`} {...restProps} element={renderElement(route)}>
        {nestedPaths.map((r, i) => renderRoutes(r, `${index}-${i}`))}
      </Route>
    } else {
      return <Route key={`${index}-${path}`} {...route} element={renderElement(route)} />
    }
  }

  return (
    <Router>
      <Routes>
        {routes.map((route, index) => renderRoutes(route, index))}
      </Routes>
    </Router>
  )
}

export default App;
