import {
    Delete as DeleteIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { useState } from 'react';
import type { File } from '../types';



const FileAccordion = ({ file, onExpand, expanded, onDelete }: { file: File, onExpand: () => void, expanded?: boolean, onDelete?: () => void, }) => {
    const [preview, setPreview] = useState<string | ArrayBuffer | null>(null)
    return <Accordion
        key={file.path}
        expanded={expanded}
        onClick={() => {
            onExpand()
            if (file.url) {
                setPreview(file.url)
            } else {
                const fileReader = new FileReader()
                fileReader.readAsDataURL(file.file)
                fileReader.onloadend = () => {
                    setPreview(fileReader.result);
                }
            }
        }}
    >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
                backgroundColor: file.dup ? red[50] : '',
                height: "50px",
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {onDelete && <IconButton
                    onClick={onDelete}
                >
                    <DeleteIcon sx={{ color: grey[700] }} />
                </IconButton>}
                <Typography sx={{ fontSize: 14 }}>
                    {file.name}
                    <b>{file.dup ? ' (Duplicated)' : ''}</b>
                </Typography>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>{file.path}</Typography>
            <img src={preview as string | undefined} style={{ maxWidth: "100%", maxHeight: "100%", marginTop: "10px" }} alt={file.path} />
        </AccordionDetails>
    </Accordion>
}

export default FileAccordion