import AddIcon from '@mui/icons-material/Add'
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from '../../helpers/axios'
import { StatusButton, getStatusOrder } from '../../helpers/status'
import ProjectForm from './components/ProjectForm'
import type { Column, ProjectObject } from './types'

const columns: Column[] = [
  {
    displayName: "Title",
    columnName: "title",
  },
  {
    displayName: "Description",
    columnName: "description",
    width: "60%"
  },
  {
    displayName: "Status",
    columnName: "status_number",
  },
  {
    displayName: "Created on",
    columnName: "createdAt",
  },
]

const Projects = () => {
  const [projects, setProjects] = useState<ProjectObject[]>([])
  const [orderBy, setOrderBy] = useState<keyof ProjectObject>("createdAt")
  const [orderAsc, setOrderAsc] = useState<boolean>(false)
  const [showCreationForm, setShowCreationForm] = useState<boolean>(false)
  const [projectFetchingError, setProjectFetchingError] = useState<null | string>(null)
  const [reloadProjects, setReloadProjects] = useState<boolean>(false)
  const [showDone, setShowDone] = useState<boolean>(false)

  const fetchProjects = () => {
    axios.get(
      `/projects`
    )
      .then(response => {
        response.data.forEach((p: { createdAt: number | Date }) => p.createdAt = new Date(p.createdAt))
        setProjectFetchingError(null)
        const projectList = response.data.map((p: ProjectObject) => { return { ...p, status_number: getStatusOrder(p.status) } })
        setProjects(projectList)
      })
      .catch((e) => {
        setProjectFetchingError(`${e.response.status}: ${e.message}`)
      })
      .finally(() => {
      })
  }

  useEffect(() => {
    fetchProjects()
    // setReloadProjects(false)
  }, [reloadProjects])

  const updateSorting = (columnName: keyof ProjectObject) => {
    setOrderAsc(columnName === orderBy ? !orderAsc : false)
    setOrderBy(columnName)
  }

  const visibleRows = useMemo(
    () => {
      const rows = [...projects].sort((a, b) => {
        if (a[orderBy] < b[orderBy]) {
          return orderAsc ? -1 : 1
        }
        if (a[orderBy] > b[orderBy]) {
          return orderAsc ? 1 : -1
        }
        return 0
      })
      return rows.filter(r => !(r.status === 'done' && !showDone))
    },
    [orderAsc, orderBy, projects, showDone],
  )

  const createSortHandler =
    (column: Column) => (event: React.MouseEvent<unknown>) => {
      updateSorting(column.columnName as keyof ProjectObject)
    }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDone(event.target.checked);
  };

  return (
    <Box
      sx={{
        maxWidth: 1200,
        minWidth: 300,
        margin: "auto",
        paddingTop: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 1
        }}
      >
        <Typography variant="h5" sx={{ marginY: "20px" }}>Projects</Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            setShowCreationForm(true)
          }}
        >
          Add project
        </Button>
      </Box>
      <Typography sx={{ fontSize: 14 }}>
        <Checkbox checked={showDone} onChange={handleCheckboxChange} />
        Show done projects
      </Typography>
      {!projectFetchingError ? <>
        <TableContainer component={(props) => <Paper {...props} elevation={0} />} sx={{ width: "100%" }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell key={index} sortDirection={orderBy === column.columnName ? (orderAsc ? 'asc' : 'desc') : false} sx={{ width: column.width ?? "auto" }}>
                    <TableSortLabel
                      active={orderBy === column.columnName}
                      direction={(orderBy === column.columnName ? orderAsc : false) ? 'asc' : 'desc'}
                      onClick={createSortHandler(column)}
                    >
                      {column.displayName}
                      {orderBy === column.columnName ? (
                        <Box component="span" sx={visuallyHidden}>
                          {orderAsc ? 'sorted ascending' : 'sorted descending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows.map((project, index) => {
                return <TableRow hover key={index} >
                  <TableCell component="th" scope="row">
                    <Typography component={Link} to={`/projects/${project.id}`} sx={{ textDecoration: "underline" }}>
                      {project.title}
                    </Typography>
                  </TableCell>
                  <TableCell>{project.description}</TableCell>
                  <TableCell>
                    <StatusButton status={project.status} />
                  </TableCell>
                  <TableCell>{project.createdAt.toLocaleString()}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme: { zIndex: { drawer: number } }) => theme.zIndex.drawer + 1 }}
          open={showCreationForm}
        >
          <ProjectForm setShowForm={setShowCreationForm} setReloadProjects={setReloadProjects} />
        </Backdrop>
      </> :
        <Typography variant='h6' color="red">{projectFetchingError}</Typography>
      }
    </Box>
  )
}

export default Projects