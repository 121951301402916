import { ReactNode } from 'react'

import {
  Home,
  LogIn,
  MainWrapper,
  Project,
  Projects,
  ProjectsWrapper
} from './pages'

interface route {
  path?: string
  element: ReactNode
  protect?: boolean
  index?: boolean
  nestedPaths?: route[]
}

const routes: route[] = [
  {
    path: '/', element: <MainWrapper />, nestedPaths: [
      {
        path: 'projects', element: <ProjectsWrapper />, protect: true, nestedPaths: [
          { path: ':projectId', element: <Project /> },
          { index: true, element: <Projects /> },
        ]
      },
      { path: 'login', element: <LogIn /> },
      { index: true, element: <Home /> },
    ]
  },
]

export default routes