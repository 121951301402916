import CloseIcon from '@mui/icons-material/Close'
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material'
import { Field, Formik, Form as FormikForm, FormikValues, } from 'formik'
import { TextField } from 'formik-mui'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import axios from '../../../helpers/axios'
import type { ProjectObject } from '../types'

const validationSchema = yup.object().shape({
    title: yup.string().required('Title is a required field'),
    description: yup.string(),
})

const ProjectForm = (
    { setReloadProjects, projectToEdit, setShowForm }
        : {
            setReloadProjects: (_: boolean) => void,
            projectToEdit?: ProjectObject,
            setShowForm: (_: boolean) => void
        }) => {
    const [requestError, setRequestError] = useState<null | string>(null)
    const navigate = useNavigate()

    const handleSubmitEdit = (values: FormikValues, { resetForm, setSubmitting }: { resetForm: () => void, setSubmitting: (_: boolean) => void }) => {
        axios.put(
            `/projects/${projectToEdit?.id}`,
            {
                ...projectToEdit,
                ...values
            },
        )
            .then(response => {
                console.log("=========================")
                console.log(response)
                console.log("=========================")
                resetForm()
                setRequestError(null)
                setShowForm(false)
            })
            .catch((e) => {
                setRequestError(`${e.response.status}: ${e.message}`)
            })
            .finally(() => {
                setReloadProjects(true)
                setSubmitting(false)
            })
    }

    const handleSubmitAdd = (values: FormikValues, { resetForm, setSubmitting }: { resetForm: () => void, setSubmitting: (_: boolean) => void }) => {
        axios.post(
            `/projects`,
            values,
        )
            .then(response => {
                resetForm()
                setRequestError(null)
                setShowForm(false)
                navigate(`/projects/${response.data.id}`)
            })
            .catch((e) => {
                setRequestError(`${e.response.status}: ${e.message}`)
            })
            .finally(() => {
                setReloadProjects(true)
                setSubmitting(false)
            })
    };

    return <Paper
        elevation={3}
        style={{
            minWidth: 280,
            maxWidth: 700,
            width: '80%',
            margin: 'auto',
            padding: 20,
        }}
    >
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Formik
                enableReinitialize
                initialValues={{ title: projectToEdit?.title ?? "", description: projectToEdit?.description ?? "" }}
                onSubmit={projectToEdit === undefined ? handleSubmitAdd : handleSubmitEdit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, resetForm }) => (
                    <FormikForm
                        onSubmit={handleSubmit}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <IconButton onClick={() => {
                                    resetForm()
                                    setRequestError(null)
                                    setShowForm(false)
                                }} sx={{ float: 'right' }} ><CloseIcon /></IconButton>
                                <Typography variant='h6'>
                                    {projectToEdit ? "Edit project" : "Add new project"}
                                </Typography>
                            </Grid>
                            {requestError && (
                                <Grid item xs={12}>
                                    <Typography variant='h6' color="red">{requestError}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name='title'
                                    label='Title'
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    name='description'
                                    label='Description'
                                    minRows={3}
                                    multiline
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    type='submit'
                                    style={{ float: 'right' }}
                                >
                                    {projectToEdit ? "Save changes" : "Create project"}
                                </Button>
                            </Grid>
                        </Grid>
                    </FormikForm>
                )}
            </Formik>
        </Grid>
    </Paper>
}

export default ProjectForm