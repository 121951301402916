import React, { useEffect, useState } from 'react'

import { NavLink, Outlet, useNavigate } from 'react-router-dom'

import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'

import {
  Menu as MenuIcon
} from '@mui/icons-material'

import axios from '../helpers/axios'
import { tokenExpired} from '../helpers/cookies'

const pages: { name: string, path: string }[] = [
  // { name: 'Projects', path: '/projects' },
  // {name: 'Image Labeling', path: '/image_labeling'}
]

const MainWrapper: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [loggedIn, setLoggedIn] = useState<boolean>(!tokenExpired())
  const navigate = useNavigate()

  useEffect(() => {
    auth()
  }, [])

  const auth = () => {
    axios.get('/auth/me')
      .then(response => {
        setLoggedIn(true)
        document.cookie = `ACCESS_GRANTED=true`
      })
      .catch(error => {
        document.cookie = 'ACCESS_GRANTED=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        setLoggedIn(false)
      })
  }

  return (
    <>
      <AppBar position='static'>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <Box
              component={NavLink}
              to='/'
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            >
              <img
                src='/attalab-logo-white.svg'
                alt='AttaLab Logo'
                style={{
                  maxHeight: 65,
                  width: 110,
                }}
              />
            </Box>

            {loggedIn && <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                onClick={e => setAnchorElNav(e.currentTarget)}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={() => setAnchorElNav(null)}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    component={NavLink}
                    to={page.path}
                    onClick={() => setAnchorElNav(null)}
                  >
                    <Typography textAlign='center'>{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>}
            <Box
              component={NavLink}
              to='/'
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <img
                src='/attalab-logo-white.svg'
                alt='AttaLab Logo'
                style={{
                  maxHeight: 65,
                  width: 110,
                }}
              />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {loggedIn && pages.map((page) => (
                <Button
                  key={page.name}
                  component={NavLink}
                  to={page.path}
                  onClick={() => setAnchorElNav(null)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
            {!loggedIn ? (
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={() => {
                  navigate('/login')
                }}
              >
                Log In
              </Button>
            ) : (
              <Button
                sx={{ my: 2, color: 'white', display: 'block' }}
                onClick={() => {
                  axios.delete(
                    '/auth/logout'
                  ).then(() => {
                    document.cookie = 'ACCESS_GRANTED=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
                    navigate('/')
                  })
                }}
              >
                Log Out
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth='xl'>
        <Outlet />
      </Container>
    </>
  )
}

export default MainWrapper