import React, { useEffect, useState } from 'react'

import { Navigate } from 'react-router-dom'
import { tokenExpired } from '../../helpers/cookies'

const Home: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(!tokenExpired())

  useEffect(() => {
    setLoggedIn(!tokenExpired())
  }, [])
  return (
    loggedIn ?
      <Navigate to='/projects' replace={true} /> :
      <Navigate to='/login' replace={true} />
  )
}

export default Home