import { useEffect, useState } from 'react'

import {
  Alert,
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import {
  Field,
  Formik,
  Form as FormikForm,
  FormikValues,
} from 'formik'
import { TextField } from 'formik-mui'
import { Navigate } from 'react-router-dom'
import * as yup from 'yup'
import axios from '../../../helpers/axios'
import { tokenExpired } from '../../../helpers/cookies'


const validationSchema = yup.object().shape({
  username: yup.string().email('must be a valid email').required('is a required field'),
  password: yup.string().required('is a required field'),
})

const LogIn = () => {
  const [message, setMessage] = useState<undefined | { body: string, status: string }>()
  const [loggedIn, setLoggedIn] = useState<boolean>(!tokenExpired())

  useEffect(() => {
    setLoggedIn(!tokenExpired())
  }, [])


  const handleSubmit = (values: FormikValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    axios.post(
      `/auth/login`,
      values,
    )
      .then(response => {
        document.cookie = `ACCESS_GRANTED=true`
        window.location.href = '/'
      })
      .catch(() => { })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    !loggedIn ?
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: 'calc(100vh - 68px)' }}
        >
          <Grid item xs={12}>
            <Paper
              elevation={3}
              style={{
                minWidth: 200,
                maxWidth: 350,
                margin: 'auto',
                padding: 20,
              }}
            >
              <Formik
                initialValues={{
                  username: '',
                  password: '',
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ handleSubmit }) => (
                  <FormikForm
                    onSubmit={handleSubmit}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography variant='h6'>
                          Log In
                        </Typography>
                      </Grid>
                      {Boolean(message) && (
                        <Grid item xs={12}>
                          <Alert
                            onClose={() => setMessage(undefined)}
                            // @ts-ignore
                            severity={(message && message.status) || 'info'}
                          // sx={{width: '100%'}}
                          >
                            {message && message.body}
                          </Alert>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name={'username'}
                          label={'Email'}
                          variant={'standard'}
                          size='small'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name='password'
                          label='Password'
                          variant='standard'
                          type='password'
                          size='small'
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant='contained'
                          type='submit'
                          style={{ float: 'right' }}
                        >
                          Log In
                        </Button>
                      </Grid>

                    </Grid>
                  </FormikForm>
                )}
              </Formik>
            </Paper>
          </Grid>
        </Grid>
      </> :
      <Navigate to='/' />
  )
}

export default LogIn