
export const getCookie = (name: string)  =>  {
  const cookies = document.cookie.split('; ')
  for (let i = 0; i < cookies.length; i++) {
    let cookiePair = cookies[i].split('=')
    if (name === cookiePair[0]) return cookiePair[1]
  }
}

export const tokenExpired = () => {
  return getCookie('ACCESS_GRANTED') === undefined;
}
