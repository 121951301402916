import { Button } from "@mui/material";
import { blue, green, grey, orange } from "@mui/material/colors";

interface Colors {
    color: string;
    backgroundColor: string;
}

const STATUS_ORDERS = new Map([
    ['active', 3],
    ['ready', 2],
    ['inactive', 1],
    ['done', 0]
])

export function getStatusOrder(status: string) {
    const res = STATUS_ORDERS.get(status)
    return res ?? -1
}

export function StatusButton({ status }: { status: string }) {
    const colors: Colors = {
        color: grey[600],
        backgroundColor: grey[50]
    }
    if (status === 'active') {
        colors.color = orange[900]
        colors.backgroundColor = orange[50]
    } else if (status === 'ready') {
        colors.color = blue[900]
        colors.backgroundColor = blue[50]
    } else if (status === 'done') {
        colors.color = green[900]
        colors.backgroundColor = green[50]
    }
    return <Button
        variant='outlined'
        disabled
        size='small'
        sx={{
            width: "100px",
            color: `${colors.color}!important`,
            borderColor: `${colors.color}!important`,
            backgroundColor: colors.backgroundColor,
        }}>
        {status}
    </Button>
}