import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Modal,
  Pagination,
  Paper,
  Tooltip,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  PlayArrow as PlayArrowIcon
} from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { CancelTokenSource } from 'axios';
import axios, { axiosCore } from '../../helpers/axios';
import { StatusButton } from '../../helpers/status';
import FileAccordion from './components/FileAccordion';
import ProjectForm from './components/ProjectForm';
import type { File, ProjectObject, UploadedFile } from './types';


interface LoadingProgress {
  fileName: string
  progress: number
}

const filesPerPage = 20

let cancelTokenSource: CancelTokenSource | null = null;

const MarkProjectReadyDialog = ({ open, setOpen, handleConfirm }: { open: boolean, setOpen: (_: boolean) => void, handleConfirm: () => void }) => {
  return <Dialog
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to change this project's status to "ready"? This action cannot be undone and you will lose the ability to upload files for this project.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="primary">
        Cancel
      </Button>
      <Button onClick={() => {
        handleConfirm()
        setOpen(false)
      }} color="primary" autoFocus>
        Mark ready
      </Button>
    </DialogActions>
  </Dialog>
}

const Project = () => {
  const params = useParams()
  const fileInputRef = useRef(null);

  const [files, setFiles] = useState<File[]>([])
  const filesRef = useRef(files)
  const [fileExpanded, setFileExpanded] = useState<undefined | string>()
  const [uploadedFileExpanded, setUploadedFileExpanded] = useState<undefined | string>()
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const uploadedFilesRef = useRef(uploadedFiles)
  const [project, setProject] = useState<undefined | ProjectObject>()
  const [loading, setLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState<undefined | LoadingProgress>()
  const [currentFilesPage, setCurrentFilesPage] = useState(1)
  const [currentUploadedFilesPage, setCurrentUploadedFilesPage] = useState(1)
  const [openDuplicatedModel, setOpenDuplicatedModel] = useState(false)
  const [duplicatedFileExpanded, setDuplicatedFileExpanded] = useState<undefined | string>()
  const [reloadProject, setReloadProject] = useState<boolean>(false)
  const [showEditForm, setShowEditForm] = useState<boolean>(false)
  const [openMarkReadyDialog, setOpenMarkReadyDialog] = useState<boolean>(false)
  const [message, setMessage] = useState<undefined | {body: string, status?: string}>()

  const startFilesIndex = (currentFilesPage - 1) * filesPerPage
  const endFilesIndex = startFilesIndex + filesPerPage
  const currentFiles = files.slice(startFilesIndex, endFilesIndex)
  const totalFilesPages = Math.ceil(files.length / filesPerPage)

  const startUploadedFilesIndex = (currentUploadedFilesPage - 1) * filesPerPage
  const endUploadedFilesIndex = startUploadedFilesIndex + filesPerPage
  const currentUploadedFiles = uploadedFiles.slice(startUploadedFilesIndex, endUploadedFilesIndex)
  const totalUploadedFilesPages = Math.ceil(uploadedFiles.length / filesPerPage)

  const [uploadingProgress, setUploadingProgress] = useState<File[]>([])
  const uploadingProgressRef = useRef(uploadingProgress)
  const [uploadingFiles, setUploadingFiles] = useState(false)

  const uploadingFilesSameTime = 8

  useEffect(() => {
    uploadingProgressRef.current = uploadingProgress
  }, [uploadingProgress]);

  useEffect(() => {
    uploadedFilesRef.current = uploadedFiles
    setUploadingProgress(up => {
      return up.filter(p => uploadedFiles.findIndex(uf => uf.extImage === p.path) < 0)
    })
  }, [uploadedFiles]);

  const compareUploadedFiles = (a: UploadedFile, b: UploadedFile) => {
    if (a.dup === b.dup) {
      return a.originalName > b.originalName ? 1 : -1
    }
    return a.dup ? -1 : 1
  }

  const compareFiles = (a: File, b: File) => {
    if (a.dup === b.dup) {
      return a.name > b.name ? 1 : -1
    }
    return a.dup ? -1 : 1
  }

  useEffect(() => {
    setLoading(true)
    axios.get(
      `/projects/${params.projectId}`
    )
      .then(response => {
        setProject(response.data.imageLabelingProject)
        response.data.images && setUploadedFiles(response.data.images.sort(compareUploadedFiles))

      })
      .catch(error => {
        setMessage({ body: error.message as string, status: 'error'})
      })
      .finally(() => {
        setLoading(false)
      })
  }, [reloadProject])

  useEffect(() => {
    if(uploadingFiles && uploadingProgress.length < uploadingFilesSameTime && files.length > 0) {
      processImagesUpload()
    } else if (uploadingFiles && uploadingProgress.length === 0) {
      setUploadingFiles(false)
    }
  }, [uploadingFiles, uploadingProgress])

  const processImagesUpload = () => {
    const numberFilesToAdd = uploadingFilesSameTime - uploadingProgress.length
    const filesToAdd = []
    let updatedFiles = [...files]

    for (let i = 0; i < numberFilesToAdd; i++) {
      let fileToAdd = updatedFiles.shift()
      if (!fileToAdd) { break }
      fileToAdd.progress = 0
      fileToAdd.cancelTokenSource = axiosCore.CancelToken.source()

      filesToAdd.push(fileToAdd)
      imageUpload(fileToAdd)
    }

    setFiles(updatedFiles)
    setUploadingProgress([...uploadingProgressRef.current, ...filesToAdd])
  }

  useEffect(() => {
    filesRef.current = files
  }, [files])

  const openModalIfDuplicates = () => {
    if (filesRef.current.findIndex(f => f.dup) >= 0) {
      setOpenDuplicatedModel(true)
    }
  }

  useEffect(() => {
    uploadedFiles.forEach(uploadedFile => uploadedFile.dup = files.findIndex(f => uploadedFile.extImage === f.path) >= 0)
    setUploadedFiles([...uploadedFiles].sort(compareUploadedFiles))
  }, [files])

  const onDrop = useCallback((event: any) => {
    event.preventDefault()
    const items = event.dataTransfer.items
    const promises = []

    setFiles([])
    setLoading(true)
    for (let item of items) {
      if (item.kind === 'file') {
        const folderEntry = item.webkitGetAsEntry()
        promises.push(readDirectory(folderEntry))
      }
    }
    Promise.all(promises)
      .then(() => {
        setTimeout(() => {
          setLoading(false)
          openModalIfDuplicates()
        }, 1000)
      })
  }, [uploadedFiles])

  const readDirectory = async (entry: any) => {
    if (entry.isDirectory) {
      const reader = entry.createReader()

      const readBatch = async () => {
        const entries: any = await readEntries(reader)
        const promises = []

        for (const childEntry of entries) {
          if (childEntry.isFile) {
            readFile(childEntry)
          } else if (childEntry.isDirectory) {
            promises.push(readDirectory(childEntry))
          }
        }

        await Promise.all(promises)
        if (entries.length > 0) {
          await readBatch()
        }
      }

      await readBatch()
    } else {
      readFile(entry)
    }
  }

  const readEntries = (reader: any) => {
    return new Promise((resolve, reject) => {
      reader.readEntries((entries: any) => {
        resolve(entries)
      }, (error: any) => {
        reject(error)
      })
    })
  }

  const readFile = (entry: any) => {
    entry.file((file: any) => {
      if ((/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file.name)) {
        setFiles(latest => [...latest, {
          name: file.name,
          path: entry.fullPath,
          file: file,
          dup: uploadedFiles.findIndex(f => f.extImage === entry.fullPath) >= 0
        }].sort(compareFiles))
      }
    })
  }

  const onFileInputChange = (event: any) => {
    if (event.target.files && event.target.files.length !== 0) {
      const newFiles: File[] = []
      for (let i = 0; i < event.target.files.length; i++) {
        if ((/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(event.target.files[i].name)) {
          newFiles.push({
            name: event.target.files[i].name,
            path: '/' + event.target.files[i].webkitRelativePath,
            file: event.target.files[i],
            dup: uploadedFiles.findIndex(f => f.extImage === '/' + event.target.files[i].webkitRelativePath) >= 0
          })
        }
      }
      setFiles(newFiles.sort(compareFiles))
      setTimeout(() => {
        openModalIfDuplicates()
      }, 100)
    }
  }

  const onDragOver = (event: any) => {
    event.preventDefault()
  }

  const openFileDialog = () => {
    if (fileInputRef.current) {
      // @ts-ignore
      fileInputRef.current.click()
    }
  }

  const handleAccordionChange = (path: string) => {
    path === fileExpanded ? setFileExpanded(undefined) : setFileExpanded(path)
  }

  const handleDuplicatedAccordionChange = (path: string) => {
    path === duplicatedFileExpanded ? setDuplicatedFileExpanded(undefined) : setDuplicatedFileExpanded(path)
  }

  const handleUploadedAccordionChange = (path: string) => {
    path === uploadedFileExpanded ? setUploadedFileExpanded(undefined) : setUploadedFileExpanded(path)
  }

  const handleUpload = () => {
    setUploadingFiles(true)
  }

  const imageUpload = async (file: File) => {
    if (files.length <= 0) {
      setLoadingProgress(undefined)
      return
    }

    cancelTokenSource = axiosCore.CancelToken.source()

    const chunkSize = 1024 * 1024 * 5

    const totalChunks = Math.ceil(file.file.size / chunkSize)

    let startByte = 0
    let sizeOfRestPayload: undefined | number = undefined
    let totalPayloadSize: undefined | number = undefined

    let chunkBaseName: string = ''

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const chunk = file.file.slice(startByte, startByte + chunkSize);
      startByte += chunkSize;

      const formData = new FormData()
      formData.append('file', chunk)
      formData.append('name', file.name)
      formData.append('extImage', file.path)
      // @ts-ignore
      formData.append('chunkIndex', chunkIndex)
      // @ts-ignore
      formData.append('totalChunks', totalChunks)
      formData.append('chunksBaseName', chunkBaseName)
      formData.append('type', file.file.type)
      let error1

      await axios.post(`/projects/${params.projectId}/imageUpload`, formData, {
        cancelToken: (cancelTokenSource as CancelTokenSource).token,
        onUploadProgress: progressEvent => {
          const newUploadingProgress = [...uploadingProgressRef.current]
          const index = newUploadingProgress.findIndex(f => f.path === file.path)
          if (index < 0) { return }

          // console.log("Index ==> ", index, newUploadingProgress)

          const lastChunkSize =  file.file.size - (chunkSize * (totalChunks - 1))

          if (lastChunkSize === file.file.size) {
            if (progressEvent.progress) {
              newUploadingProgress[index].progress = progressEvent.progress
              setUploadingProgress(newUploadingProgress)
            }
          } else {
            if (progressEvent.total) {
              if (sizeOfRestPayload === undefined) {
                sizeOfRestPayload = progressEvent.total - chunkSize
                totalPayloadSize = file.file.size + sizeOfRestPayload * totalChunks
              }
              if (totalPayloadSize) {
                newUploadingProgress[index].progress = 1 / totalPayloadSize * (chunkSize * chunkIndex + progressEvent.loaded)
                setUploadingProgress(newUploadingProgress)
              }
            }
          }
        }
      })
        .then(response => {
          if (!chunkBaseName && response.data && response.data.intImage) {
            chunkBaseName = response.data.intImage
          }
          if (response.data && response.data.imgRegistry) {
            processUploadedImage(response.data.imgRegistry)
          }

        })
        .catch(error => {
          if (error.name === 'CanceledError') {
            console.log('Request canceled:', error.message)
          } else {
            console.log('Error:', error)
          }
          setUploadingProgress([...uploadingProgressRef.current.filter(up => up.path !== file.path)])
          setMessage({ body: error.message as string, status: 'error'})
          setLoadingProgress(undefined)
          error1 = true
        })
        .finally(() => {
        })
      if (error1) {
        break
      }
    }
  }

  const processUploadedImage = (uploadedFile: UploadedFile) => {
    const fileIndex = uploadedFilesRef.current.findIndex(f => f.id === uploadedFile.id)
    if (fileIndex >= 0) {
      uploadedFilesRef.current[fileIndex] = uploadedFile
    } else {
      uploadedFilesRef.current.push(uploadedFile)
    }
    setUploadedFiles([...uploadedFilesRef.current.sort(compareUploadedFiles)])
  }

  const setReadyStatus = (project: ProjectObject | undefined) => {
    if (!project) {
      return
    }
    axios.put(
      `/projects/${project.id}/lockProject`,
      {
        ...project,
        status: 'ready',
      },
    )
      .then(response => {
        setProject(response.data)
      })
      .catch((error) => {
        console.log('Error:', error)
        setMessage({ body: error.message as string, status: 'error'})
      })
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme: { zIndex: { drawer: number } }) => theme.zIndex.drawer + 1 }}
        open={showEditForm}
      >
        <ProjectForm setShowForm={setShowEditForm} setReloadProjects={setReloadProject} projectToEdit={project} />
      </Backdrop>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={5000}
        onClose={() => setMessage(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setMessage(undefined)}
          // @ts-ignore
          severity={(message && message.status) || 'info'}
          sx={{width: '100%'}}
        >
          {message && message.body}
        </Alert>
      </Snackbar>
      <MarkProjectReadyDialog open={openMarkReadyDialog} setOpen={setOpenMarkReadyDialog} handleConfirm={() => setReadyStatus(project)} />
      {project && (
        <Box sx={{
          maxWidth: 1200,
          minWidth: 300,
          margin: "auto",
          paddingTop: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "column"
        }}>
          <Modal
            open={openDuplicatedModel}
          >
            <Box
              sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "80%",
                bgcolor: 'background.paper',
                boxShadow: 24,
                pt: 2,
                px: 4,
                pb: 3,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                  >
                    Duplicate file paths found!
                  </Typography>
                  <Typography fontSize={14}>
                    Please remove any files from the list below that you do not wish to replace by clicking the bin icon. Rename these files before uploading them again.
                  </Typography>
                </Grid>
                <Grid item xs={6}
                  sx={{
                    maxHeight: 500,
                    overflowY: 'auto',
                  }}>
                  <Typography fontSize={15}>
                    Files for upload:
                  </Typography>
                  {files.map(f => {
                    if (!f.dup) { return undefined }
                    return (
                      <FileAccordion
                        key={f.path}
                        file={f}
                        onExpand={() => handleDuplicatedAccordionChange(f.path)}
                        expanded={duplicatedFileExpanded === f.path}
                        onDelete={() => {
                          const index = files.findIndex(file => file.path === f.path)
                          const newFiles = [...files]
                          newFiles.splice(index, 1)
                          setFiles(newFiles)
                        }}
                      />
                    )
                  })}
                </Grid>
                <Grid item xs={6}
                  sx={{
                    maxHeight: 500,
                    overflowY: 'auto',
                  }}>
                  <Typography fontSize={14}>
                    Uploaded files:
                  </Typography>
                  {uploadedFiles.map(f => {
                    if (!f.dup) { return undefined }
                    return (
                      <FileAccordion
                        key={f.extImage}
                        file={{ path: f.extImage, name: f.originalName, dup: !!f.dup, url: f.intImage }}
                        onExpand={() => handleUploadedAccordionChange(f.extImage)}
                        expanded={uploadedFileExpanded === f.extImage}
                      />
                    )
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setOpenDuplicatedModel(false)
                    }}
                  >
                    Replace all
                  </Button>
                  <Button
                    variant='contained'
                    style={{
                      float: 'right',
                    }}
                    onClick={() => {
                      setFiles(
                        [...files.filter(f => !f.dup)]
                      )
                      setOpenDuplicatedModel(false)
                    }}
                  >
                    Skip all
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Typography component={Link} to="/projects" color="inherit">Projects</Typography>
                <Typography color="text.primary">{project.title}</Typography>
              </Breadcrumbs>
            </Grid>

            {project.status === "inactive" &&
              <Grid item>
                {uploadedFiles?.length ?
                  <Button startIcon={<PlayArrowIcon />} onClick={() => { setOpenMarkReadyDialog(true) }} disabled={uploadedFiles.length === 0}>
                    Mark project ready
                  </Button> :
                  <Tooltip title={<Typography fontSize={13}>Upload files to mark project ready</Typography>} >
                    <div>
                      <Button startIcon={<PlayArrowIcon />} onClick={() => { setOpenMarkReadyDialog(true) }} disabled={uploadedFiles.length === 0}>
                        Mark project ready
                      </Button>
                    </div>
                  </Tooltip>}
              </Grid>}
            <Grid item >
              <Button startIcon={<EditIcon />} onClick={() => {
                setShowEditForm(true)
              }}>
                Edit project
              </Button>
            </Grid>

            <Grid item xs={12}>
              <span style={{ marginRight: "20px" }}><StatusButton status={project.status} /></span>
              <Typography
                sx={{
                  fontSize: 20,
                  display: "inline"
                }}
              >
                {project.title}
              </Typography>
            </Grid>

            <Grid item xs={12}><Typography
              sx={{
                fontSize: 14,
                color: grey[700]
              }}
            >
              {project.description}
            </Typography></Grid>
          </Grid>
          <Box hidden={project?.status !== "inactive"}>
            <Box
              style={{
                border: '2px dashed #eeeeee',
                borderRadius: '5px',
                padding: 20,
                textAlign: 'center',
                cursor: 'pointer',
                marginBottom: 30,
                marginTop: 30
              }}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onClick={openFileDialog}

            >
              <Typography onDragOver={onDragOver} >Drag & Drop your files here</Typography>
              <Typography sx={{ m: 1 }} onDragOver={onDragOver}>OR</Typography>
              <Button
                variant="contained"
                color="primary"
                component="span"
                onDragOver={onDragOver}
              >
                Select Folder
              </Button>
              <input
                ref={i => {
                  if (i) {
                    i.setAttribute('webkitdirectory', '')
                    // i.setAttribute('accept', 'image/*')
                    // i.setAttribute('multiple', '')
                    // @ts-ignore
                    fileInputRef.current = i
                  }
                }}
                type="file"
                style={{ display: 'none' }}
                onChange={onFileInputChange}
                onDragOver={onDragOver}
              />
            </Box>
            <Button
              color='primary'
              variant='contained'
              onClick={handleUpload}
              disabled={!!loadingProgress || files.length === 0}
              size='large'
              sx={{ width: "100%" }}
            >
              Upload {files.length} files
            </Button>
          </Box>
          {(files.length > 0 || uploadedFiles.length > 0) && (
            <Grid container spacing={1} columnSpacing={2}>
              <Grid item xs={12} sx={{ mb: "20px" }} >
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  // open={!!loadingProgress && files.length !== 0}
                  open={uploadingFiles && files.length !== 0}
                >
                  {uploadingFiles && uploadingProgress.length &&
                    <Paper
                      elevation={3}
                      style={{
                        padding: 30,
                        width: '100%',
                        maxWidth: 400,
                      }}
                    >
                      <Typography
                        variant='subtitle2'
                      >
                        Files left: {files.length}
                      </Typography>
                      {uploadingProgress.map(file => (
                        <div
                          key={file.path}
                        >
                          <Typography
                            variant='subtitle1'
                          >
                            File name: {file.name}
                          </Typography>
                          <Box
                            sx={{
                              width: '100%',
                              maxWidth: 400,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box sx={{ width: '100%', mr: 1 }}>
                              <LinearProgress
                                variant='determinate'
                                value={file.progress ? file.progress * 100 : 0}
                              />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {(() => {
                                  let precent = Math.round(
                                    file.progress ? file.progress * 100 : 0,
                                  )
                                  precent = precent === 100 ? 99 : precent
                                  return `${precent}%`
                                })()}
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                      ))}
                      <Button
                        variant='contained'
                        color='error'
                        onClick={() => {
                          uploadingProgress.map(file => {
                            file.cancelTokenSource?.cancel('Upload canceled by the user.')
                          })
                          setUploadingProgress([])
                          setUploadingFiles(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </Paper>
                  }
                </Backdrop>
              </Grid>
              <Grid
                md={6}
                item
                hidden={project?.status !== "inactive"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{ fontSize: 15, display: "inline", flexGrow: 1 }}
                >
                  Files for upload: {files.length}
                </Typography>
                {project?.status === "inactive" && (
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      setFiles([])
                    }}
                  >
                    Remove all
                  </Button>
                )}
              </Grid>
              <Grid
                md={project?.status !== "inactive" ? 12 : 6}
                item
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  sx={{ fontSize: 15, display: "inline" }}
                >
                  Uploaded files: {uploadedFiles.length}
                </Typography>
              </Grid>
              <Grid
                md={6}
                item
                hidden={project?.status !== "inactive"}
              >
                <Paper
                  elevation={3}
                  style={{
                    minHeight: 48,
                  }}
                >
                  {currentFiles.map((f, i) => (
                    <FileAccordion
                      key={f.path}
                      file={f}
                      onExpand={() => handleAccordionChange(f.path)}
                      expanded={fileExpanded === f.path}
                      onDelete={() => {
                        const index = files.findIndex(file => file.path === f.path)
                        const newFiles = [...files]
                        newFiles.splice(index, 1)
                        setFiles(newFiles)
                      }}
                    />
                  ))}
                </Paper>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Pagination
                    count={totalFilesPages}
                    page={currentFilesPage}
                    onChange={(_, newPage) => {
                      setCurrentFilesPage(newPage)
                    }}
                  />
                </div>
              </Grid>
              <Grid
                md={project?.status !== "inactive" ? 12 : 6}
                item
              >
                <Paper
                  elevation={3}
                  style={{
                    minHeight: 48,
                  }}
                >
                  {currentUploadedFiles.map((f, i) => (
                    <FileAccordion
                      key={f.extImage}
                      file={{ path: f.extImage, name: f.originalName, dup: !!f.dup, url: f.intImage }}
                      onExpand={() => handleUploadedAccordionChange(f.extImage)}
                      expanded={uploadedFileExpanded === f.extImage}
                    />
                  ))}
                </Paper>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Pagination
                    count={totalUploadedFilesPages}
                    page={currentUploadedFilesPage}
                    onChange={(_, newPage) => {
                      setCurrentUploadedFilesPage(newPage)
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </>
  )
}

export default Project